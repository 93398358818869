<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput v-model="dr_id" placeholder="DR ID"/>
        </CCol>
        <CCol lg="3">
          <Datepicker v-model="delivery_date_from" input-class="form-control" placeholder="Delivery date from" />
        </CCol>
        <CCol lg="3">
          <Datepicker v-model="delivery_date_to" input-class="form-control" placeholder="Delivery date from" />
        </CCol>
         <CCol lg="1">
          <!-- <v-select 
            label="booking_no" 
            :options="bookingList.data"
            :reduce="item => item.id"
            v-model="booking_id"
            placeholder="Booking No."
          > 
          </v-select>   -->
        </CCol>
        
        <CCol lg="2" class="float-right">
          <CButton size="sm" block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
        </CCol>
      </CRow>
      <CRow>
       
        <!-- <CCol lg="2">
          <CInput size="sm" v-model="released_by" placeholder="Released By"/>
        </CCol>
         <CCol lg="2">
          <CInput size="sm" v-model="checked_by" placeholder="Checked By"/>
        </CCol>
         <CCol lg="2">
          <CInput size="sm" v-model="received_by" placeholder="Received By"/>
        </CCol> -->
        <!-- <CCol lg="3">
          
        </CCol> -->
        <CCol lg="3">
            <v-select 
           
            label="customer_name" 
            :options="customerList.data"
            :reduce="item => item.id"
            v-model="customer_id"
            placeholder="Customer"
          > 
          </v-select>  
        </CCol>
         <CCol lg="3">
           <CInput v-model="asset_no" placeholder="Asset No."/>
        </CCol>
        
         <CCol lg="1">
        </CCol>
         <CCol lg="3">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol>
      </CRow>
      <CRow>
      
         <!-- <CCol lg="2">
          <v-select 
            label="name" 
            :options="personnel_list.data"
            :reduce="item => item.id"
            v-model="delivered_by"
            placeholder="Delivered By"
          > 
          </v-select>  
        </CCol>  -->
      </CRow>
  </div>
</template>

<script>
  import config from '../config.js';
  import axios from '../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
  import Datepicker from 'vuejs-datepicker';
  import moment from 'moment';
  import Swal from 'sweetalert2';
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            dr_id: "",
            delivery_date_from: moment(new Date()).subtract(6, 'month')._d,
            delivery_date_to: new Date(),
            booking_id: "",
            customer_id: "",
            checked_by: "",
            released_by: "",
            received_by: "",
            delivered_by: "", 
            asset_no : "",
            
            // bookingList: {
            //   data: []
            // },
            customerList: {
              data: []
            },
            // personnel_list: {
            //   data: []
            // }
          }
        },
      mounted() {
        // this.getBooking();
        this.getCustomer();
        // this.getPersonnel(); 
      },
      components: {vSelect, Datepicker},
      methods: {
        search() {
          const data = {
            dr_id: this.dr_id,
            delivery_date_from: this.delivery_date_from,
            delivery_date_to: this.delivery_date_to,
            booking_id: this.booking_id,
            customer_id: this.customer_id,
            checked_by: this.checked_by,
            released_by: this.released_by,
            received_by: this.received_by,
            delivered_by: this.delivered_by, 
            asset_no : this.asset_no
          }
          if(moment(this.delivery_date_to).diff(moment(this.delivery_date_from), 'months', true) > 6){
              Swal.fire({
                title: 'Note!',
                text: "Delivery date range should not exceed 6 months.",
                icon: 'warning', 
              })
            }
            else{
              this.$emit("depot-search-query", data)
            } 
        },
        clearSearch() {
          this.dr_id = "";
          this.delivery_date_from =  moment(new Date()).subtract(6, 'month')._d;
          this.delivery_date_to = new Date();
          this.booking_id = "";
          this.customer_id = "";
          this.checked_by = "";
          this.released_by = "";
          this.received_by = "";
          this.delivered_by = "";
          this.asset_no = "";
   
          const data = {
            dr_id: this.dr_id,
            delivery_date_from: moment(new Date()).subtract(6, 'month')._d,
            delivery_date_to: new Date(),
            booking_id: this.booking_id,
            customer_id: this.customer_id,
            checked_by: this.checked_by,
            released_by: this.released_by,
            received_by: this.received_by,
            delivered_by: this.delivered_by, 
            asset_no: this.asset_no
          }
          this.$emit("depot-search-query", data)
        }, 

      // getBooking(){

      //   axios.get(config.api_path+'/booking?page=1&limit=10000000')
      //   .then(response => {
      //     this.bookingList = response.data; 
      //     this.bookingList.data.unshift({
      //       booking_no: 'All',
      //       id: 'all'
      //     })
      //   })

      // },
      getCustomer(){
	    	axios.get(config.api_path+'/reference/customer-list', {
            params: {
              show_all : true
            }
          })
	    	.then(response => {
	    		this.customerList = response.data; 
	    	})

	    },
      // getPersonnel(){

      //   axios.get(config.api_path+'/personnel?page=1&limit=10000000')
      //   .then(response => {
      //     this.personnel_list = response.data; 
      //     this.personnel_list.data.unshift({
      //       name: 'All',
      //       id: 'all'
      //     })
      //   })

      // },

      
      }
    }
</script>

<style scoped>

</style>
